import React from 'react';
import logo from '../../../assets/icons/logo.png';
import { makeStyles } from '@mui/styles';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { LoginValidator } from '../../../validators';
import { FormikValidator } from '../../../utility';
import { useAuthContext } from '../../contexts';
import { API } from '../../../api';

const useStyles = makeStyles({
  content: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '20px',

    '& .logo': {
      padding: '20px 0'
    },
    '& .title': {
      textAlign: 'center',
      width: '100%',
      color: '#13132B',
      fontSize: '24px',
      fontWeight: 'bold'
    },

    '& .text-input': {
      width: '100%'
    },

    '& .submit-button': {
      width: '100%',
      height: '50px',
      backgroundColor: '#13132A',
      borderRadius: '5px',
      color: '#fff'
    },
    '& .text': {
      fontSize: '16px',
      color: '#808080',
      textAlign: 'center',
      paddingTop: '10px'
    }
  }
});

export function Login(): JSX.Element {
  const classes = useStyles();
  const authContext = useAuthContext();
  const formik = useFormik<LoginValidator>({
    initialValues: {
      username: '',
      password: ''
    },
    validate: FormikValidator.validator(LoginValidator),
    onSubmit: (values, formikHelpers) => {
      API.Auth.login(values)
        .then((response) => {
          authContext.updateState(response.data);
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
          formikHelpers.setErrors(FormikValidator.serverErrors(error.response));
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    }
  });

  return (
    <form className={classes.content} onSubmit={formik.handleSubmit}>
      <img src={logo} className="logo" alt="" />
      <div className="title">Login to your account</div>
      <TextField
        required={true}
        id="standard-basic"
        error={Boolean(formik.touched.username && formik.errors.username)}
        helperText={formik.touched.username && formik.errors.username}
        className="text-input"
        name="username"
        label="Email"
        variant="outlined"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <TextField
        required={true}
        id="standard-basic"
        className="text-input"
        error={Boolean(formik.touched.password && formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        name="password"
        label="Password"
        type="password"
        variant="outlined"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Button type="submit" className="submit-button" variant="contained">
        Login
      </Button>
      <div className="text">
        If you don't have an account <Link to="/register">Sign Up</Link>
      </div>
    </form>
  );
}
