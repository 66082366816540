export class CleanValues {
  static clean<T = any>(data: T): T {
    if (typeof data === 'object' && Array.isArray(data) && data !== null) {
      return CleanValues.clearArray(data) as T;
    }
    if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
      return CleanValues.cleanObject(data) as T;
    }
    return data;
  }

  static clearArray(data: any): any {
    const newArray: any = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      if (typeof item === 'object' && Array.isArray(item) && item !== null) {
        const newItem = CleanValues.clearArray(item);
        if (newItem.length) {
          newArray.push(newItem);
        }
      } else if (
        typeof item === 'object' &&
        !Array.isArray(item) &&
        item !== null
      ) {
        const newItem: object = CleanValues.cleanObject(item);
        if (Object.values(newItem).length) {
          newArray.push(newItem);
        }
      } else if (item !== undefined && item !== null && item !== '') {
        newArray.push(item);
      }
    }
    return newArray;
  }

  static cleanObject(data: any): any {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      if (
        typeof data[key] === 'object' &&
        !Array.isArray(data[key]) &&
        data[key] !== undefined &&
        data[key] !== null &&
        data[key].constructor.name === 'Object'
      ) {
        const newItem = CleanValues.cleanObject(data[key]);
        if (Object.values(newItem).length) {
          data[key] = newItem;
        } else {
          delete data[key];
        }
      } else if (
        typeof data[key] === 'object' &&
        Array.isArray(data[key]) &&
        data[key] !== undefined &&
        data[key] !== null
      ) {
        const newItem = CleanValues.clearArray(data[key]);
        if (newItem.length) {
          data[key] = newItem;
        } else {
          delete data[key];
        }
      } else if (
        data[key] === null ||
        (data[key] === undefined && data[key] === '')
      ) {
        delete data[key];
      }
    }
    return data;
  }
}
