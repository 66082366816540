import React, { createContext, PureComponent, useContext } from 'react';
import { Cookies } from '../../utility';

interface State {
  user: AuthTypes.User;
  isAuthenticated: boolean;
  isInitialized: boolean;
}

interface AuthContextInterface extends State {
  updateState: (user: AuthTypes.User) => void;
  updateToken: (user: AuthTypes.RefreshResponse) => void;
}

export const AuthContext = createContext<AuthContextInterface>(null);

export const useAuthContext = () =>
  useContext<AuthContextInterface>(AuthContext);

const getState = (): State => {
  const state: State = {
    user: null,
    isAuthenticated: false,
    isInitialized: false
  };
  const user = Cookies.get('current-user');

  if (user) {
    state.user = user;
    state.isAuthenticated = true;
  }

  return state;
};

export class AuthContextProvider extends PureComponent<any, State> {
  state: State = getState();

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isInitialized: true
      });
    }, 2000);
  }

  updateToken = (data: AuthTypes.RefreshResponse) => {
    let userData: AuthTypes.User = this.state.user;
    if (userData) {
      userData.access = data.access;
      userData.refresh = data.refresh;
      Cookies.set('current-user', userData);
      Cookies.set('access-token', data.access);
      Cookies.set('refresh-token', data.refresh);
      this.setState({ user: userData });
    }
  };

  updateState = (user: AuthTypes.User) => {
    let isAuthenticated;
    let userData: AuthTypes.User = user;
    if (userData) {
      isAuthenticated = true;
      userData = {
        ...(this.state.user || {}),
        ...userData
      };
      if (!userData.access) {
        userData.access = Cookies.get('access-token');
      }
      if (!userData.refresh) {
        userData.refresh = Cookies.get('refresh-token');
      }

      Cookies.set('current-user', userData);
      Cookies.set('access-token', userData.access);
      Cookies.set('refresh-token', userData.refresh);
    } else {
      isAuthenticated = false;
      Cookies.remove('current-user');
      Cookies.remove('access-token');
      Cookies.remove('refresh-token');
    }
    this.setState({ user: userData, isAuthenticated });
  };

  render() {
    const context: AuthContextInterface = {
      ...this.state,
      updateState: this.updateState,
      updateToken: this.updateToken
    };
    return (
      <AuthContext.Provider value={context}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
