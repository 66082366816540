import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  MoreVert as MoreVertIcon,
  PhotoCameraOutlined as PhotoCameraOutlinedIcon
} from '@mui/icons-material';
import { LoginCard, OTPCard, VerificationCard } from './components';
import { makeStyles } from '@mui/styles';
import { API } from '../../../../api';

const useStyles = makeStyles({
  card: {
    height: 'calc(100vh - 120px)'
  },
  ringAvatar: {
    backgroundColor: '#1C9AD6'
  },
  arloAvatar: {
    backgroundColor: '#49B48A'
  }
});

export function AddAccount(): JSX.Element {
  const classes = useStyles();
  const [arloAccounts, setArloAccounts] = useState<ArloTypes.Account[]>([]);
  const [ringAccounts, setRingAccounts] = useState<RingTypes.Account[]>([]);

  const getArloAccountsList = useCallback(() => {
    API.Arlo.accountList().then((response) => {
      setArloAccounts(response.data);
    });
  }, []);

  const getRingAccountsList = useCallback(() => {
    API.Ring.accountList().then((response) => {
      setRingAccounts(response.data);
    });
  }, []);

  useEffect(() => {
    getArloAccountsList();
    getRingAccountsList();
  }, [getArloAccountsList, getRingAccountsList]);
  const [status, setStatus] = useState<
    'login' | 'arlo-verification' | 'arlo-verify'
  >('login');
  const [loginResponse, setLoginResponse] =
    useState<ArloTypes.LoginResponse>(null);

  const [verificationTypeResponse, setVerificationTypeResponse] =
    useState<ArloTypes.VerificationTypeResponse>(null);

  const updateLoginResponse = useCallback((data: ArloTypes.LoginResponse) => {
    setLoginResponse(data);
    if (data.items) {
      setStatus('arlo-verification');
    }
  }, []);

  const updateVerificationTypeResponse = (
    data: ArloTypes.VerificationTypeResponse
  ) => {
    setVerificationTypeResponse(data);
    setStatus('arlo-verify');
  };

  const handleVerificationSuccess = (data: ArloTypes.AccountAuth) => {
    console.log('handleVerificationSuccess', data);
    setStatus('login');
    setLoginResponse(null);
    setVerificationTypeResponse(null);
    getArloAccountsList();
  };

  const onSuccess = () => {
    getRingAccountsList();
  };

  return (
    <Box sx={{ p: 3, m: 'auto' }}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardHeader sx={{ bgcolor: '#F9F9F9' }} title="My Accounts" />
            <CardContent sx={{ p: 0, height: '100%' }}>
              <List sx={{ width: '100%', m: 'auto' }}>
                {arloAccounts.map((account, index) => (
                  <Box>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={account.short_title}
                          sx={{ bgcolor: '#49B48A' }}>
                          {account.short_title}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={account.title}
                        secondary={
                          <React.Fragment>{account.email}</React.Fragment>
                        }
                      />
                      <Button aria-label="add to favorites">
                        <PhotoCameraOutlinedIcon />
                        <Typography
                          variant="body2"
                          sx={{ pl: 1 }}
                          color="text.secondary"
                          textAlign="center">
                          {account.cameras_count}
                        </Typography>
                      </Button>
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    </ListItem>
                    {index != arloAccounts.length - 1 && (
                      <Divider variant="middle" />
                    )}
                  </Box>
                ))}
                {ringAccounts.map((account, index) => (
                  <Box>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={account.short_title}
                          sx={{ bgcolor: '#1C9AD6' }}>
                          {account.short_title}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={account.title}
                        secondary={
                          <React.Fragment>{account.email}</React.Fragment>
                        }
                      />
                      <Button aria-label="add to favorites">
                        <PhotoCameraOutlinedIcon />
                        <Typography
                          variant="body2"
                          sx={{ pl: 1 }}
                          color="text.secondary"
                          textAlign="center">
                          {account.cameras_count}
                        </Typography>
                      </Button>
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    </ListItem>
                    {index != ringAccounts.length - 1 && (
                      <Divider variant="middle" />
                    )}
                  </Box>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardHeader sx={{ bgcolor: '#F9F9F9' }} title="Add Account" />
            {status === 'login' && (
              <LoginCard
                onResponse={updateLoginResponse}
                onSuccess={onSuccess}
              />
            )}
            {status === 'arlo-verification' && !!loginResponse && (
              <VerificationCard
                data={loginResponse}
                onResponse={updateVerificationTypeResponse}
              />
            )}
            {status === 'arlo-verify' && !!verificationTypeResponse && (
              <OTPCard
                data={verificationTypeResponse}
                onResponse={handleVerificationSuccess}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
