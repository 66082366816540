import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from './components';
import { Box } from '@mui/material';

export function MainLayout(): JSX.Element {
  return (
    <Box>
      <Navbar />

      <Outlet />
    </Box>
  );
}
