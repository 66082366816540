import { axiosInstance } from './axiosInstance';
import { AxiosPromise } from 'axios';

export class Arlo {
  static addAccount(
    data: ArloTypes.Login
  ): AxiosPromise<ArloTypes.LoginResponse> {
    return axiosInstance.request({
      url: '/arlo/add-account/',
      method: 'post',
      data
    });
  }

  static accountList(): AxiosPromise<ArloTypes.Account[]> {
    return axiosInstance.request({
      url: '/arlo/account-list/',
      method: 'get'
    });
  }

  static initializeVerification(
    id: string,
    data: ArloTypes.VerificationTypeRequest
  ): AxiosPromise<ArloTypes.VerificationTypeResponse> {
    return axiosInstance.request({
      url: `/arlo/send-verification-code/${id}/`,
      method: 'post',
      data
    });
  }

  static verifyOtp(
    id: string,
    data: ArloTypes.VerifyOTPRequest
  ): AxiosPromise<ArloTypes.AccountAuth> {
    return axiosInstance.request({
      url: `/arlo/verification-code/${id}/`,
      method: 'post',
      data
    });
  }

  static startCameraStream(
    id: string,
    data: ArloTypes.StartCameraStreamRequest
  ): AxiosPromise<ArloTypes.StartCameraStreamResponse> {
    return axiosInstance.request({
      url: `/arlo/start-camera-stream/${id}/`,
      method: 'post',
      data
    });
  }

  static cameraList(id?: string): AxiosPromise<ArloTypes.Camera[]> {
    return axiosInstance.request({
      url: id ? `/arlo/camera-list/${id}/` : '/arlo/camera-list/',
      method: 'get'
    });
  }
}
