import axios, { AxiosPromise, CancelTokenSource } from 'axios';
import { Cookies } from '../utility';

const url = new URL(process.env.REACT_APP_API_URL);
url.pathname = 'api';

export const axiosInstance = axios.create({
  baseURL: url.href
});

const pendingRequests: Record<string, CancelTokenSource> = {};

axiosInstance.interceptors.request.use((config) => {
  if (pendingRequests[config.url]) {
    pendingRequests[config.url].cancel();
  }
  const cancelTokenSource = axios.CancelToken.source();
  config.cancelToken = cancelTokenSource.token;
  pendingRequests[config.url] = cancelTokenSource;
  const token = Cookies.get('access-token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    delete pendingRequests[response.config.url];
    return response;
  },
  (error) => {
    if (!axios.isCancel(error)) {
      delete pendingRequests[error.config.url];
    }

    return Promise.reject(error);
  }
);

export type APIResponsePromise<T> = AxiosPromise<T>;
