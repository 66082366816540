import {
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsPhoneNumber,
  IsString,
  MinLength,
  ValidateIf
} from 'class-validator';
import { IsMatch } from './custom-class-validator';

export class RegisterValidator implements AuthTypes.Register {
  @IsString()
  @IsNotEmpty()
  first_name: string;

  @IsString()
  @IsNotEmpty()
  last_name: string;

  @IsString()
  @IsEmail()
  email: string;

  @IsOptional()
  @IsString()
  @IsPhoneNumber(null)
  @ValidateIf((object, value) => {
    return !!value;
  })
  phone_number: string;

  @IsString()
  @MinLength(8)
  password: string;

  @IsString()
  @MinLength(8)
  @IsMatch('password', { message: 'password and confirm password must match' })
  password_confirm: string;
}
