import React from 'react';
import { Main } from './app/Main';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './app/contexts';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './utility/theme';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <div className="App">
            <Main />
          </div>
        </AuthContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
