import { Auth } from './Auth';
import { Arlo } from './Arlo';
import { Ring } from './Ring';
import { axiosInstance } from './axiosInstance';

export const API = {
  Arlo,
  Ring,
  Auth,
  axiosInstance
};
