import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthLayout, MainLayout } from './layouts';
import { Home, Accounts, AddAccount } from './views/Main';
import { Login, Register } from './views/Auth';
import { useAuthContext } from './contexts';
import { Box } from '@mui/material';

export function Main(): JSX.Element {
  const authContext = useAuthContext();
  return (
    <Box>
      <Routes>
        {!authContext.isAuthenticated ? (
          <Route element={<AuthLayout />} path="/">
            <Route element={<Login />} path="/login" />
            <Route element={<Register />} path="/register" />
            <Route element={<Navigate to="/login" />} path="/" />
          </Route>
        ) : (
          <Route element={<MainLayout />} path="/">
            <Route path="/home" element={<Home />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/add-accounts" element={<AddAccount />} />
            <Route element={<Navigate to="/home" />} path="/" />
          </Route>
        )}
        <Route element={<Navigate to="/" />} path="*" />
      </Routes>
    </Box>
  );
}
