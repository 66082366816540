import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { API } from '../../../api';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { makeStyles } from '@mui/styles';
import { ArloCameraVideoModal, RingCameraVideoModal } from '../../components';

const useStyles = makeStyles({
  cardMediaBox: {
    position: 'relative',
    width: '100%',
    height: '220px',

    '&:not(:hover)': {
      '& .play-button': {
        display: 'none'
      }
    }
  }
});

export function Home(): JSX.Element {
  const classes = useStyles();
  const [selectedArloCamera, setSelectedArloCamera] =
    useState<ArloTypes.Camera>(null);
  const [selectedRingCamera, setRingSelectedCamera] =
    useState<RingTypes.Device>(null);
  const [arloCameras, setArloCameras] = useState<ArloTypes.Camera[]>([]);
  const [ringCameras, setRingCameras] = useState<RingTypes.Device[]>([]);
  useEffect(() => {
    API.Arlo.cameraList().then((response) => {
      setArloCameras(response.data);
    });
    API.Ring.cameraList().then((response) => {
      setRingCameras(response.data);
    });
  }, []);

  const handleCloseArloCameraModal = () => {
    setSelectedArloCamera(null);
  };

  const handleCloseRingCameraModal = () => {
    setRingSelectedCamera(null);
  };
  const handleOpenRingCameraModal = (camera: RingTypes.Device) => () => {
    API.Ring.startCameraStream(camera.account_id, {
      camera_id: camera.id
    }).then(() => {
      setRingSelectedCamera(camera);
    });
  };
  const handleOpenArloCameraModal = (camera: ArloTypes.Camera) => () => {
    API.Arlo.startCameraStream(camera.account_id, {
      uniqueId: camera.uniqueId
    }).then(() => {
      setSelectedArloCamera(camera);
    });
  };
  return (
    <Box sx={{ p: 3, m: 'auto' }}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {arloCameras.map((camera) => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={camera.uniqueId}>
            <Card sx={{ maxWidth: 445 }}>
              <CardContent>
                <Box className={classes.cardMediaBox}>
                  <CardMedia
                    component="img"
                    height="100%"
                    width="100%"
                    image={camera.presignedLastImageUrl}
                    alt="green iguana"
                    sx={{ borderRadius: 1, objectFit: 'cover' }}
                  />
                  <Chip
                    label="Arlo"
                    size="small"
                    sx={{
                      position: 'absolute',
                      left: '0px',
                      top: '0px',
                      px: 1,
                      transform: 'translate(12px, 12px)',
                      bgcolor: '#33CC99',
                      color: 'white'
                    }}
                  />
                  <IconButton
                    className="play-button"
                    onClick={handleOpenArloCameraModal(camera)}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}>
                    <PlayCircleFilledWhiteIcon
                      sx={{ fontSize: '65px', color: 'info.light' }}
                    />
                  </IconButton>
                </Box>
              </CardContent>
              <CardActions sx={{ px: 2, pb: 2, pt: 0 }}>
                <Box>
                  <Typography gutterBottom variant="h5" component="div">
                    {camera.deviceName}
                  </Typography>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '3px'
                    }}>
                    <RadioButtonCheckedIcon
                      sx={{ color: 'error.main', fontSize: '16px' }}
                    />
                    <Typography variant="body2" sx={{ color: 'error.main' }}>
                      Live
                    </Typography>
                  </Box>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
        {ringCameras.map((camera) => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={camera.camera_id}>
            <Card sx={{ maxWidth: 445 }}>
              <CardContent>
                <Box className={classes.cardMediaBox}>
                  <CardMedia
                    component="img"
                    height="100%"
                    width="100%"
                    image={`${process.env.REACT_APP_API_URL}${camera.snapshot}`}
                    alt="green iguana"
                    sx={{ borderRadius: 1, objectFit: 'cover' }}
                  />
                  <Chip
                    label="Ring"
                    size="small"
                    sx={{
                      position: 'absolute',
                      left: '0px',
                      top: '0px',
                      px: 1,
                      transform: 'translate(12px, 12px)',
                      bgcolor: '#1C9AD6',
                      color: 'white'
                    }}
                  />
                  <IconButton
                    className="play-button"
                    onClick={handleOpenRingCameraModal(camera)}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)'
                    }}>
                    <PlayCircleFilledWhiteIcon
                      sx={{ fontSize: '65px', color: 'info.light' }}
                    />
                  </IconButton>
                </Box>
              </CardContent>
              <CardActions sx={{ px: 2, pb: 2, pt: 0 }}>
                <Box>
                  <Typography gutterBottom variant="h5" component="div">
                    {camera.deviceName}
                  </Typography>
                  <Box
                    sx={{
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '3px'
                    }}>
                    <RadioButtonCheckedIcon
                      sx={{ color: 'error.main', fontSize: '16px' }}
                    />
                    <Typography variant="body2" sx={{ color: 'error.main' }}>
                      Live
                    </Typography>
                  </Box>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {!!selectedArloCamera && (
        <ArloCameraVideoModal
          open={!!selectedArloCamera}
          onClose={handleCloseArloCameraModal}
          camera={selectedArloCamera}
        />
      )}
      {!!selectedRingCamera && (
        <RingCameraVideoModal
          open={!!selectedRingCamera}
          onClose={handleCloseRingCameraModal}
          camera={selectedRingCamera}
        />
      )}
    </Box>
  );
}
