import React from 'react';
import {
  Box,
  Button,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { API } from '../../../../../api';
import { useFormik } from 'formik';
import { FormikValidator } from '../../../../../utility';

interface Props {
  data: ArloTypes.LoginResponse;
  onResponse: (data: ArloTypes.VerificationTypeResponse) => void;
}
export function VerificationCard(props: Props): JSX.Element {
  const items = props.data.items.filter(
    (item) => item.factorType === 'SMS' || item.factorType === 'EMAIL'
  );

  const formik = useFormik<ArloTypes.VerificationTypeRequest>({
    initialValues: {
      factorType: 'SMS',
      factorId: ''
    },
    onSubmit: (values, formikHelpers) => {
      API.Arlo.initializeVerification(props.data.id, values)
        .then((response) => {
          console.log(response);
          props.onResponse(response.data);
        })
        .catch((error) => {
          console.log(error.response);
          formikHelpers.setErrors(FormikValidator.serverErrors(error.response));
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    }
  });

  const handleChangeValue = (item: ArloTypes.LoginFactorItem) => () => {
    formik.setValues({
      factorType: item.factorType,
      factorId: item.factorId
    });
  };
  return (
    <CardContent component="form" onSubmit={formik.handleSubmit}>
      <Typography gutterBottom variant="h5" component="div">
        Verification
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
        sint. Velit officia consequat duis enim velit mollit
      </Typography>
      <FormControl sx={{ py: 2 }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Email"
          name="radio-buttons-group">
          {items.map((item) => (
            <FormControlLabel
              value={item.factorRole}
              checked={item.factorId === formik.values.factorId}
              control={<Radio />}
              onClick={handleChangeValue(item)}
              label={
                <Box>
                  <Typography variant="subtitle2">{item.factorType}</Typography>
                  <Typography variant="body2" color="gray">
                    {item.displayName}
                  </Typography>
                </Box>
              }
            />
          ))}
        </RadioGroup>
        {/*<Typography gutterBottom variant="h5" component="div">*/}
        {/*  Push Notification*/}
        {/*</Typography>*/}
        {/*<Typography variant="body2" color="text.secondary">*/}
        {/*  You have login at 3 mobiles*/}
        {/*</Typography>*/}
        <Button sx={{ mt: 2, py: 1 }} variant="contained" type="submit">
          Send OTP
        </Button>
      </FormControl>
    </CardContent>
  );
}
