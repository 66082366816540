import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import arloLogo from '../../../assets/icons/arlo.svg';
import ringLogo from '../../../assets/icons/ring.svg';
import { makeStyles } from '@mui/styles';
import addIcon from '../../../assets/images/add-icon.png';
import { API } from '../../../api';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  userImg: {
    textAlign: 'center'
  }
}));

export function Accounts(): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();
  const [arloAccounts, setArloAccounts] = useState<ArloTypes.Account[]>([]);
  const [ringAccounts, setRingAccounts] = useState<ArloTypes.Account[]>([]);

  useEffect(() => {
    API.Arlo.accountList().then((response) => {
      setArloAccounts(response.data);
    });
    API.Ring.accountList().then((response) => {
      setRingAccounts(response.data);
    });
  }, []);

  const handleClickAddAccount = () => {
    navigate('/add-accounts');
  };
  return (
    <Box sx={{ p: 3, m: 'auto' }}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {arloAccounts.map((account) => (
          <Grid item xs={6} md={4} lg={3}>
            <Card sx={{ maxWidth: 345 }}>
              <CardContent>
                <Box
                  sx={{
                    width: '100%',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    my: 2,
                    position: 'relative'
                  }}>
                  <IconButton
                    aria-label="settings"
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      transform: 'translate(10px, -25px)'
                    }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Avatar
                    sx={{
                      width: 120,
                      height: 120,
                      fontSize: 55,
                      fontWeight: 'bold',
                      bgcolor: '#49B48A'
                    }}>
                    {account.short_title}
                  </Avatar>
                </Box>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center">
                  {account.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center">
                  {account.email}
                </Typography>
              </CardContent>
              <Divider light />
              <CardActions
                sx={{ justifyContent: 'space-between', px: 2, py: 1 }}>
                <Box>
                  <img src={arloLogo} alt="arlo" />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PhotoCameraOutlinedIcon />
                  <Typography
                    variant="body2"
                    sx={{ pl: 1 }}
                    color="text.secondary"
                    textAlign="center">
                    {account.cameras_count}
                  </Typography>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
        {ringAccounts.map((account) => (
          <Grid item xs={6} md={4} lg={3}>
            <Card sx={{ maxWidth: 345 }}>
              <CardContent>
                <Box
                  sx={{
                    width: '100%',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    my: 2,
                    position: 'relative'
                  }}>
                  <IconButton
                    aria-label="settings"
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      transform: 'translate(10px, -25px)'
                    }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Avatar
                    sx={{
                      width: 120,
                      height: 120,
                      fontSize: 55,
                      fontWeight: 'bold',
                      bgcolor: '#1C9AD6'
                    }}>
                    {account.short_title}
                  </Avatar>
                </Box>

                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  textAlign="center">
                  {account.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center">
                  {account.email}
                </Typography>
              </CardContent>
              <Divider light />
              <CardActions
                sx={{ justifyContent: 'space-between', px: 2, py: 1 }}>
                <Box>
                  <img src={ringLogo} alt="ring" />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PhotoCameraOutlinedIcon />
                  <Typography
                    variant="body2"
                    sx={{ pl: 1 }}
                    color="text.secondary"
                    textAlign="center">
                    {account.cameras_count}
                  </Typography>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
        <Grid item xs={6} md={4} lg={3}>
          <Card
            component={Button}
            onClick={handleClickAddAccount}
            sx={{
              maxWidth: 345,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <CardMedia className={classes.userImg}>
              <img src={addIcon} alt="" />
            </CardMedia>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="p"
                textAlign="center"
                textTransform="capitalize">
                Add New Account
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
