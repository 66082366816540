import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardHeader, Modal } from '@mui/material';
import HLS from 'hls.js';

interface Props {
  open: boolean;
  onClose: () => void;
  camera: RingTypes.Device;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(700px, calc(100% - 50px))',
  boxShadow: 24,
  maxHeight: 'calc(100vh - 100px)'
};

export function RingCameraVideoModal(props: Props): JSX.Element {
  const [player, setPlayer] = useState<HLS>(null);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const videoElement = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `media/hls/${props.camera.id}/stream.m3u8`;
    setVideoUrl(url.href);
    const hlsPlayer = new HLS();
    setPlayer(hlsPlayer);

    return () => {
      hlsPlayer.stopLoad();
    };
  }, []);

  useEffect(() => {
    if (videoElement.current && player && videoUrl) {
      player.loadSource(videoUrl);
      const interval = setInterval(() => {
        console.log('Interval');
        player.loadSource(videoUrl);
      }, 3000);
      player.attachMedia(videoElement.current);
      player.on(HLS.Events.MANIFEST_PARSED, function () {
        videoElement.current.play();
        clearInterval(interval);
        console.log('Starting playback');
        videoElement.current.currentTime = videoElement.current.duration;
      });
    }
  }, [player, videoUrl, videoElement.current]);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Card sx={style}>
        <CardHeader title={props.camera.data.description} />
        <Box sx={{ p: 2, pt: 0 }}>
          <video
            controls={true}
            ref={videoElement}
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </Box>
      </Card>
    </Modal>
  );
}
