import React from 'react';
import { Box, Card } from '@mui/material';
import { Outlet } from 'react-router-dom';
import backgroundImage from '../../../assets/images/bg.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    width: '500px'
  }
});

export function AuthLayout(): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Card className={classes.card} sx={{ p: 4 }}>
        <Outlet />
      </Card>
    </Box>
  );
}
