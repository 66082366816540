import { axiosInstance } from './axiosInstance';
import { AxiosPromise } from 'axios';

export class Auth {
  static login(data: AuthTypes.Login): AxiosPromise<AuthTypes.User> {
    return axiosInstance.request({
      url: '/login/',
      method: 'post',
      data
    });
  }

  static refresh(
    data: AuthTypes.Refresh
  ): AxiosPromise<AuthTypes.RefreshResponse> {
    return axiosInstance.request({
      url: '/refresh-token/',
      method: 'post',
      data
    });
  }

  static register(data: AuthTypes.Register): AxiosPromise<AuthTypes.User> {
    return axiosInstance.request({
      url: '/register/',
      method: 'post',
      data
    });
  }
}
