import React, { useEffect } from 'react';
import { Box, Button, CardContent, TextField } from '@mui/material';
import otpImg from '../../../../../assets/images/otp-img.png';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { API } from '../../../../../api';
import { FormikValidator } from '../../../../../utility';

interface Props {
  data: ArloTypes.VerificationTypeResponse;
  onResponse: (data: ArloTypes.AccountAuth) => void;
}

export function OTPCard(props: Props): JSX.Element {
  const formik = useFormik<ArloTypes.VerifyOTPRequest>({
    initialValues: {
      factorAuthCode: '',
      otp: ''
    },
    onSubmit: (values, formikHelpers) => {
      API.Arlo.verifyOtp(props.data.id, values)
        .then((response) => {
          props.onResponse(response.data);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response) {
            formikHelpers.setErrors(
              FormikValidator.serverErrors(error.response)
            );
          }
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    }
  });

  useEffect(() => {
    formik.setFieldValue('factorAuthCode', props.data.factorAuthCode);
  }, [props.data]);
  return (
    <CardContent component="form" onSubmit={formik.handleSubmit}>
      <Box sx={{ my: 3 }}>
        <img src={otpImg} alt="" />
      </Box>
      <Typography gutterBottom variant="h5" component="div">
        OTP Verification
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
        sint. Velit officia consequat duis enim velit mollit
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <TextField
          required={true}
          id="standard-basic"
          className="text-input"
          name="otp"
          label="OTP"
          variant="outlined"
          error={Boolean(formik.touched.otp && formik.errors.otp)}
          helperText={formik.touched.otp && formik.errors.otp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          sx={{ mt: 2 }}
        />
      </Box>
      <Button sx={{ mt: 2, py: 1 }} variant="contained" type="submit">
        Verify Account
      </Button>
    </CardContent>
  );
}
