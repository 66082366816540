import React from 'react';
import { Box, Button, CardContent, Grid, TextField } from '@mui/material';
import arloLogo from '../../../../../assets/images/arlo-company.png';
import ringLogo from '../../../../../assets/images/ring-company.png';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { API } from '../../../../../api';
import { FormikValidator } from '../../../../../utility';
import { AxiosError } from 'axios';

const useStyles = makeStyles({
  cameraImageCard: {
    background: '#F9F9F9',
    borderRadius: 10,
    height: 'calc(100vh - 470px)',
    width: '100%',
    cursor: 'pointer',

    '&.has-otp': {
      height: 'calc(100vh - 540px)'
    },
    '&:hover': {
      '&:not(.active)': {
        '&.ring': {
          outline: '1px solid #1C9AD6'
        },
        '&.arlo': {
          outline: '1px solid #49B48A'
        }
      }
    },
    '&.disabled': {
      cursor: 'not-allowed',
      '&:hover': {
        outline: 'unset'
      }
    },
    '&.active': {
      '&.ring': {
        outline: '3px solid #1C9AD6'
      },
      '&.arlo': {
        outline: '3px solid #49B48A'
      }
    },
    '& img': {
      width: 'calc(100% - 30px)',
      height: 'calc(100% - 30px)',
      objectFit: 'contain'
    }
  }
});

interface Props {
  onResponse: (data: ArloTypes.LoginResponse) => void;
  onSuccess: () => void;
}
export function LoginCard(props: Props): JSX.Element {
  const classes = useStyles();
  const formik = useFormik<
    (ArloTypes.Login | RingTypes.Login) & { otpRequired: boolean }
  >({
    initialValues: {
      email: '',
      password: '',
      otp: '',
      otpRequired: false,
      camera: 'arlo'
    },
    onSubmit: (values, formikHelpers) => {
      if (values.camera === 'arlo') {
        API.Arlo.addAccount(values)
          .then((response) => {
            console.log(response);
            props.onResponse(response.data);
          })
          .catch((error: AxiosError) => {
            console.log(error.response);
            if (error.response) {
              formikHelpers.setErrors(
                FormikValidator.serverErrors(error.response)
              );
            }
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          });
      } else if (values.camera === 'ring') {
        API.Ring.addAccount(values)
          .then((response) => {
            console.log(response);
            props.onSuccess();
            formikHelpers.resetForm();
          })
          .catch((error: AxiosError) => {
            if (error.response.status === 412) {
              formikHelpers.setFieldValue('otpRequired', true);
            }
            console.log(error.response);
            if (error.response) {
              formikHelpers.setErrors(
                FormikValidator.serverErrors(error.response)
              );
            }
          })
          .finally(() => {
            formikHelpers.setSubmitting(false);
          });
      }
    }
  });
  const changeCameraType = (value: 'arlo' | 'ring') => () => {
    formik.setFieldValue('camera', value);
  };
  return (
    <CardContent sx={{ p: 0 }}>
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={6} textAlign={'center'}>
          <Button
            className={clsx(classes.cameraImageCard, 'arlo', {
              active: formik.values.camera === 'arlo',
              'has-otp': formik.values.otpRequired
            })}
            onClick={changeCameraType('arlo')}>
            <img src={arloLogo} alt="" />
          </Button>
        </Grid>
        <Grid item xs={6} textAlign={'center'}>
          <Button
            className={clsx(classes.cameraImageCard, 'ring', {
              active: formik.values.camera === 'ring',
              'has-otp': formik.values.otpRequired
            })}
            onClick={changeCameraType('ring')}>
            <img src={ringLogo} alt="" />
          </Button>
        </Grid>
      </Grid>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', p: 2 }}
        component="form"
        onSubmit={formik.handleSubmit}>
        <TextField
          required={true}
          id="standard-basic"
          className="text-input"
          name="email"
          type="email"
          label="Email"
          variant="outlined"
          error={Boolean(formik.touched.email && formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          sx={{ mt: 2 }}
        />
        <TextField
          required={true}
          id="standard-basic"
          className="text-input"
          name="password"
          label="Password"
          type="password"
          error={Boolean(formik.touched.password && formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          variant="outlined"
          sx={{ mt: 2 }}
        />
        {formik.values.otpRequired && (
          <TextField
            required={true}
            id="standard-basic"
            className="text-input"
            name="otp"
            label="OTP"
            type="text"
            error={Boolean(formik.touched.otp && formik.errors.otp)}
            helperText={formik.touched.password && formik.errors.otp}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.otp}
            variant="outlined"
            sx={{ mt: 2 }}
          />
        )}

        <Button
          type="submit"
          className="submit-button"
          variant="contained"
          sx={{ py: 2, mt: 2 }}>
          Login
        </Button>
      </Box>
    </CardContent>
  );
}
