import moment from 'moment';

type SameSite = 'lax' | 'strict' | 'none';

export interface CookieOptions {
  expiry?: Date;
  path?: string;
  domain?: string;
  maxAge?: number;
  secure?: boolean;
  sameSite?: SameSite;
  session?: boolean;
}

interface CookiesList {
  [key: string]: string | object;
}

export class Cookies {
  private static expiry = new Date(0).toUTCString();

  public static all(): CookiesList {
    return document.cookie.split(';').reduce((res: any, c: string) => {
      const [key, ...val] = c.trim().split('=').map(decodeURIComponent);
      if (key) {
        try {
          return Object.assign(res, { [key]: JSON.parse(val.join('=')) });
        } catch (e) {
          return Object.assign(res, { [key]: val.join('=') });
        }
      }
    }, {});
  }

  public static get<T = any>(key: string): T | any {
    const keyName = `${key}=`;
    const cookie = document.cookie
      .split(';')
      .map((item) => decodeURIComponent(item.trim()))
      .find((item) => item.startsWith(keyName));
    if (cookie) {
      try {
        return JSON.parse(cookie.replace(keyName, ''));
      } catch (e) {
        return cookie.replace(keyName, '');
      }
    } else {
      return null;
    }
  }

  public static exist<T = any>(key: string): boolean {
    return !!this.get<T>(key);
  }

  public static set<T = any>(
    name: string,
    value: T,
    options: CookieOptions = {
      path: '/',
      sameSite: 'strict',
      session: true
    }
  ): void {
    let cookieData = '';
    try {
      cookieData = JSON.stringify(value);
    } catch (e) {
      cookieData = name;
    }
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
      cookieData
    )};`;

    if (options.sameSite) {
      cookie += `SameSite=${options.sameSite};`;
    }
    if (options.session) {
      cookie += ' expires=0;';
    } else {
      const expiry = options.expiry
        ? options.expiry.toUTCString()
        : moment().add(1, 'y').toDate().toUTCString();
      cookie += ` expires=${expiry};`;
    }
    cookie += options.path ? ` path=${options.path};` : '';
    document.cookie = cookie;
  }

  public static remove(key: string): void {
    document.cookie = `${key}=; expires=${this.expiry}; path=/;`;
  }

  public static clear(): void {
    const cookies = this.all();
    if (cookies) {
      Object.keys(cookies).forEach((key) => {
        this.remove(key);
      });
    }
  }
}
