import { axiosInstance } from './axiosInstance';
import { AxiosPromise } from 'axios';

export class Ring {
  static addAccount(data: RingTypes.Login): AxiosPromise<RingTypes.Account> {
    return axiosInstance.request({
      url: '/ring/add-account/',
      method: 'post',
      data
    });
  }

  static accountList(): AxiosPromise<RingTypes.Account[]> {
    return axiosInstance.request({
      url: '/ring/account-list/',
      method: 'get'
    });
  }

  static cameraList(): AxiosPromise<RingTypes.Device[]> {
    return axiosInstance.request({
      url: '/ring/camera-list/',
      method: 'get'
    });
  }

  static cameraListByAccountId(id: string): AxiosPromise<RingTypes.Device[]> {
    return axiosInstance.request({
      url: `/ring/camera-list/${id}/`,
      method: 'get'
    });
  }

  static startCameraStream(
    id: string,
    data: RingTypes.StartCameraStreamRequest
  ): AxiosPromise<RingTypes.StartCameraStreamResponse> {
    return axiosInstance.request({
      url: `/ring/start-camera-stream/${id}/`,
      method: 'post',
      data
    });
  }
}
